













































import {Vue,Component,Prop} from 'vue-property-decorator';
import Usuario from '@/entidades/Maestro/Usuario';
import Usuario2 from '@/entidades/Maestro/Usuario2';
import moment from 'moment';
import {PlanificacionCabeceraRequest} from '../../../entidades/Plantacion/PlanificacionForestalResponse';
@Component({
  name : 'FormularioAdd',

})
export default class FormularioAdd extends Vue
{

  @Prop({type:Boolean,required:true}) modal!:boolean;
  listadoUsuario:Array<Usuario2> = [];
  listadoYear:Array<number> = [];
  listadoMes:{id:number,descripcion:string}[] = [
    {id: 0 , descripcion : 'ENERO'},
    {id: 1 , descripcion : 'FEBRERO'},
    {id: 2 , descripcion : 'MARZO'},
    {id: 3 , descripcion : 'ABRIL'},
    {id: 4 , descripcion : 'MAYO'},
    {id: 5 , descripcion : 'JUNIO'},
    {id: 6 , descripcion : 'JULIO'},
    {id: 7 , descripcion : 'AGOSTO'},
    {id: 8 , descripcion : 'SEPTIEMBRE'},
    {id: 9 , descripcion : 'OCTUBRE'},
    {id: 10 , descripcion : 'NOVIEMBRE'},
    {id: 11 , descripcion : 'DICIEMBRE'}
  ]
  listadoFiltrado:{id:number,descripcion:string}[] = [

  ]
  mesId:number=0;
  anioId:number=0;
  usuarioIdLocal:string ="";

  closeModalLocal()
  {
    this.$emit('closeModal');
  }

  changeMes(anio:number)
  {
    let anioActual = moment().year();
    if(anioActual == anio)
    {
      let mesActual = moment().month();
      this.listadoFiltrado = this.listadoMes.filter(t=>t.id >=mesActual);

    }
    else
    {
      this.listadoFiltrado = this.listadoMes;
    }
    console.log('indice',this.listadoFiltrado[0].id)
    this.mesId = this.listadoFiltrado[0].id;
  }
  async obtenerUsuario()
  {
    try
    {
      var response = await Usuario.ObtenerRolName('jefezona',1)
      if(response.isSuccess)
          this.listadoUsuario = response.isResult;
    }
    catch(error)
    {

    }
    finally
    {

    }
  }

  async guardarPlanificacion()
  {
    try
    {
      var request = new PlanificacionCabeceraRequest();
      request.anio = this.anioId;
      request.mesDescripcion = this.listadoFiltrado.find(t=>t.id == this.mesId)?.descripcion!;
      request.mes = this.mesId;
      request.usuarioIdJefeZona = this.usuarioIdLocal;
      request.jefeZona = this.listadoUsuario.find(t=>t.usuarioId == this.usuarioIdLocal)?.fullName!;
      this.$emit('guardarPlanificacion',request);
    }
    catch(error)
    {

    }
    finally
    {

    }
  }

  establecerAnio()
  {
    var momento = moment().year();
    this.listadoYear.push(momento);
    this.listadoYear.push(momento+1);
  }

  mounted() {
    this.obtenerUsuario();
    this.establecerAnio();
  }
}
